<template>
  <section class="wrap">
    <slot name="title">
      <p class="big-title">
        Bienvenue sur widddi.com !
      </p>
      <p>Choisissez le type d'objet que vous souhaitez personnaliser</p>
    </slot>

    <div class="col-big-vspace articles-container">
      <router-link
        class="widddi-articles"
        v-for="(article, index) in articles"
        :key="index"
        :to="article.link"
      >
        <div class="widddi-articles__image">
          <img
            :src="article.src"
            :alt="`Image ${article.name || ''} ${article.variant || ''}`"
          >
        </div>
        <div class="widddi-articles__item">
          <p
            v-if="article.name"
            class="widddi-articles__item__title"
          >
            {{ article.name }}
          </p>

          <p
            v-if="article.variant"
            class="widddi-articles__item__sub-title"
          >
            {{ article.variant }}
          </p>

          <ul
            class="widddi-articles__item__list"
            v-if="article.list"
          >
            <li
              v-for="(item, id) in article.list"
              :key="id"
            >
              <span>{{ item }}</span>
            </li>
          </ul>

          <router-link :to="article.link" tag="a" class="widddi-articles__item__button">
            {{ action }}
          </router-link>
        </div>
      </router-link>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    articles: {
      type: Array,
      default: () => [],
    },

    action: {
      type: String,
      default: 'Personnaliser',
    },
  },
};
</script>
